/* Custom style to make block buttons behave better
==========================================================================*/
.button-wrapper>button.btn,a.btn {
	margin: 10px 0px;
}
.button-wrapper {
	display: flex;
    flex-flow: column wrap;
	justify-content: space-around;
}
@include media-breakpoint-up(sm) {
  	.button-wrapper {
		align-items: center;
    	flex-flow: row wrap;
    	-ms-flex-flow: row wrap;
    	-webkit-flex-flow: row wrap;
  	}
    .btn-container-center>button.btn,a.btn {
      margin-left: 6px;
      margin-right: 6px;
    }
    .btn-container-center {
      justify-content: center;
    }
    .btn-container-center>button.btn:first-of-type,a.btn:first-of-type {
      margin-left: 0;
    }
    .btn-container-center>button.btn:last-of-type,a.btn:last-of-type {
      margin-right: 0;
    }
    .btn-container-left>button.btn,a.btn {
      margin-left: 6px;
      margin-right: 6px;
    }
    .btn-container-left {
      justify-content: flex-start;
    }
    .btn-container-left>button.btn:first-of-type,a.btn:first-of-type {
      margin-left: 0;
    }
    .btn-container-left>button.btn:last-of-type,a.btn:last-of-type {
      margin-right: 0;
    }
    .btn-container-right>button.btn,a.btn {
      margin-left: 6px;
      margin-right: 6px;
    }
    .btn-container-right {
      justify-content: flex-end;
    }
    .btn-container-right>button.btn:first-of-type,a.btn:first-of-type {
      margin-left: 0;
    }
    .btn-container-right>button.btn:last-of-type,a.btn:last-of-type {
      margin-right: 0;
    }
    .btn-container-stretch>button.btn,a.btn {
      margin-left: 6px;
      margin-right: 6px;
    }
    .btn-container-stretch {
      justify-content: space-between;
    }
    .btn-container-stretch>button.btn:first-of-type,a.btn:first-of-type {
      margin-left: 0;
    }
    .btn-container-stretch>button.btn:last-of-type,a.btn:last-of-type {
      margin-right: 0;
    }
    .btn-container-col-center {
      flex-direction: column;
      align-items: center;
    }
    .btn-container-col-left {
      flex-direction: column;
      align-items: flex-start;
    }
    .btn-container-col-right {
      flex-direction: column;
      align-items: flex-end;
    }
  }