// Fonts

$font-family-sans-serif:
// Safari for OS X and iOS (San Francisco)
    'Roboto',
    // Chrome < 56 for OS X (San Francisco)
    'Roboto',
    // Windows
    'Roboto',
    // Android
    'Roboto',
    // Basic web fallback
    'Helvetica Neue',
    Arial, sans-serif,
    // Emoji fonts
    'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol';

/* open-sans-regular - latin */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    src: url('assets/fonts/open-sans-v18-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''), url('assets/fonts/open-sans-v18-latin-regular.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */ url('assets/fonts/open-sans-v18-latin-regular.woff2') format('woff2'),
        /* Super Modern Browsers */ url('assets/fonts/open-sans-v18-latin-regular.woff') format('woff'),
        /* Modern Browsers */ url('assets/fonts/open-sans-v18-latin-regular.ttf') format('truetype'),
        /* Safari, Android, iOS */ url('assets/fonts/open-sans-v18-latin-regular.svg#OpenSans') format('svg'); /* Legacy iOS */
}

/* open-sans-700 - latin */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    src: url('assets/fonts/open-sans-v18-latin-700.eot'); /* IE9 Compat Modes */
    src: local(''), url('assets/fonts/open-sans-v18-latin-700.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */ url('assets/fonts/open-sans-v18-latin-700.woff2') format('woff2'),
        /* Super Modern Browsers */ url('assets/fonts/open-sans-v18-latin-700.woff') format('woff'),
        /* Modern Browsers */ url('assets/fonts/open-sans-v18-latin-700.ttf') format('truetype'),
        /* Safari, Android, iOS */ url('assets/fonts/open-sans-v18-latin-700.svg#OpenSans') format('svg'); /* Legacy iOS */
}

/* Button Radius */
$btn-border-radius: 0rem;
$btn-border-radius-lg: 0rem;
$btn-border-radius-sm: 0rem;

/* Colors */
$BCS_blue: #184876;
$BCS_yellow: #ffcc28;
$BCS_green: #225530;
$BCS_green-light: #23833d;
$BCS_brown: #322725;
$BCS_blue-dark: #0b2035;
$white: #ffffff;

$primary: $BCS_green;
$secondary: $white;
$activeListItemBg: tint-color($primary, 65%);

// Body
$body-color: $BCS_brown;
$link-color: $BCS_green-light;

@import 'bootstrap/scss/bootstrap.scss';
@import '~font-awesome/css/font-awesome.css';
@import '~@ng-select/ng-select/themes/default.theme.css';
@import '../_common/style/better-buttons.scss';
@import '../_common/style/dropdown-icon.scss';
@import '../_common/style/tooltips.scss';
@import '../_common/style/zoom-in-mobile-fix.scss';
@import '../_lang/uk/styles.scss';

// Fonts
$web-font-path: 'https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700';
@import url($web-font-path);

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
}

.lead {
    font-weight: 400;
}

.navbar-nav {
    .nav-item {
        a.dropdown-item:hover {
            background-color: $activeListItemBg;
        }

        .dropdown-item.nav-link:hover,
        .nav-link:hover {
            text-decoration: $link-hover-decoration;
        }

        .dropdown-item.active,
        .dropdown-item:active {
            background-color: transparent;
        }

        .dropdown-item:hover,
        .dropdown-item:focus {
            text-decoration: none;
            background-color: transparent;
        }
    }
}
.navbar-light .navbar-nav .active {
    font-weight: bold;
}

.navbar-light .navbar-nav .nav-link {
    color: $body-color;
}

.navbar-light .navbar-nav .nav-link.active {
    color: $primary;
}

.black-trans-bg {
    background-color: rgba(0, 0, 0, 0.5);
}

.bg-highlight-one {
    background-color: $gray-100;
}

.bg-highlight-two {
    background-color: $BCS_yellow;
}

.bg-highlight-three {
    background-color: #f5fdf2;
}

ngb-accordion h5 button.btn {
    white-space: normal !important;
    text-align: left;
}

.tooltip-icon {
    cursor: pointer;
    border: none;
    outline: none !important;
    background: none;
    color: #adb5bd !important;
}

.tooltip-icon:hover {
    color: $primary !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

/* Hero image */
.hero-image-container {
    padding-top: 0px;
}

.hero-image {
    background-repeat: no-repeat;
    background-color: white;
}

.hero-image-small {
    background-repeat: no-repeat;
    background-color: white;
}

.hero-image {
    min-height: 180px;
    background-size: cover;
    background-position: center left;
}
.hero-image-container {
    min-height: 180px;
}

@include media-breakpoint-up(sm) {
    .hero-image {
        min-height: 250px;
        background-size: cover;
        background-position: center left;
    }
    .hero-image-container {
        min-height: 250px;
    }
}

@include media-breakpoint-up(md) {
    .hero-image {
        min-height: 430px;
        background-size: cover;
        background-position: center left;
    }
    .hero-image-container {
        min-height: 430px;
    }
}

@include media-breakpoint-up(lg) {
    .hero-image {
        min-height: 430px;
        background-size: auto;
        background-position: center bottom;
    }
    .hero-image-container {
        min-height: 430px;
    }
}

@include media-breakpoint-up(xl) {
    .hero-image {
        min-height: 430px;
        background-size: auto;
        background-position: center bottom;
    }
    .hero-image-container {
        min-height: 430px;
    }
}

.hero-image-small {
    min-height: 200px;
    background-size: cover;
    background-position: center right;
}

@include media-breakpoint-up(sm) {
    .hero-image-small {
        min-height: 300px;
        background-size: cover;
        background-position: center center;
    }
}

@include media-breakpoint-up(md) {
    .hero-image-small {
        min-height: 300px;
        background-size: cover;
        background-position: center center;
    }
}

@include media-breakpoint-up(lg) {
    .hero-image-small {
        min-height: 350px;
        background-size: auto;
        background-position: center bottom;
    }
}

@include media-breakpoint-up(xl) {
    .hero-image-small {
        min-height: 350px;
        background-size: auto;
        background-position: center bottom;
    }
}

/* Stepper css */

.sticky-container {
    background: $white;
}

.step-item {
    flex: 1 1 0px;
    position: relative;
    text-align: center;
}

.step-item:not(:first-child)::before {
    background: $gray-600;
    content: '';
    height: 2px;
    left: -50%;
    position: absolute;
    top: 9px;
    width: 100%;
}

.step-item a {
    display: inline-block;
    padding: 20px 10px 0;
    text-decoration: none;
}

.steps-icon {
    border-radius: 50%;
    height: 2rem;
    position: absolute;
    top: -0.4rem;
    left: calc(50% - 1rem);
    width: 2rem;
    z-index: 1;
    padding-top: 0.3rem;
    padding-right: 0.05rem;
    border-style: Solid;
    border-width: 2px;
}

.steps-icon.fa.fa-bolt {
    padding-left: 0.1rem;
}

.not-clickable {
    cursor: default;
}

.notdone {
    background: $white;
    color: $gray-600;
    border-color: $gray-600;
}

.doing {
    background: $white;
    color: $primary;
    border-color: $primary;
}

.done {
    background: $primary;
    color: $white;
    border-color: $primary;
}

.done:hover {
    background: $white;
    color: $primary;
    border-color: $primary;
}
.not-clickable .done:hover {
    background: $primary;
    color: $white;
    border-color: $primary;
}

label {
    margin-bottom: 0.1rem !important;
    font-weight: 500;
}

.custom-control-label {
    font-weight: normal;
}

.disclaimer {
    font-size: 0.9rem;
}

/* Font scaling (experimental) */

html {
    font-size: 0.875rem;
}

@include media-breakpoint-up(lg) {
    html {
        font-size: 1rem;
    }
}

/* Checkboxes & Radio buttons */

.custom-control-label::before {
    background-color: $white;
    border: $gray-500 solid 1px;
}

/* Buttons */
.btn-secondary {
    border-color: $primary;
    color: $primary;
}

.btn-secondary:hover {
    background-color: $primary;
    border-color: $primary;
    color: $white;
}

.btn-secondary:focus,
.btn-secondary:not(:disabled):not(.disabled):active {
    background-color: shade-color($primary, 5%);
    border-color: $primary;
    color: $white;
}

/* Edge doesnt properly scale svg's */
svg {
    width: 100%;
}

/* Cookiebar */

a#CybotCookiebotDialogBodyLevelButtonAccept {
    display: inline-block !important;
    background-color: $success !important;
    border: 1px solid $success !important;
    border: 1px solid transparent !important;
    border-radius: 0.25rem !important;
    font-size: 1rem !important;
}

a#CybotCookiebotDialogBodyLevelButtonAccept:focus {
    rgba: ($component-active-bg, 0.25) !important;
}

a#CybotCookiebotDialogBodyLevelButtonAccept:hover {
    rgba: ($component-active-bg, 0.25) !important;
}

#CybotCookiebotDialog {
    font-family: $font-family-sans-serif !important;
}

#CybotCookiebotDialog a,
#CybotCookiebotDialog div,
#CybotCookiebotDialogBodyContentTitle {
    font-family: $font-family-sans-serif !important;
}

/* Custom style to make block buttons behave better
-------------------------------------------------- */
@media (max-width: 767px) {
    .btn-xs-block {
        display: block;
        width: 100%;
    }
    input[type='submit'].btn-xs-block,
    input[type='reset'].btn-xs-block,
    input[type='button'].btn-xs-block {
        width: 100%;
    }
    .btn-block + .btn-xs-block,
    .btn-xs-block + .btn-block,
    .btn-xs-block + .btn-xs-block {
        margin-top: 0.5rem;
    }
}
@media (min-width: 768px) and (max-width: 991px) {
    .btn-sm-block {
        display: block;
        width: 100%;
    }
    input[type='submit'].btn-sm-block,
    input[type='reset'].btn-sm-block,
    input[type='button'].btn-sm-block {
        width: 100%;
    }
    .btn-block + .btn-sm-block,
    .btn-sm-block + .btn-block,
    .btn-sm-block + .btn-sm-block {
        margin-top: 0.5rem;
    }
}
@media (min-width: 992px) and (max-width: 1199px) {
    .btn-md-block {
        display: block;
        width: 100%;
    }
    input[type='submit'].btn-md-block,
    input[type='reset'].btn-md-block,
    input[type='button'].btn-md-block {
        width: 100%;
    }
    .btn-block + .btn-md-block,
    .btn-md-block + .btn-block,
    .btn-md-block + .btn-md-block {
        margin-top: 0.5rem;
    }
}
@media (min-width: 1200px) {
    .btn-lg-block {
        display: block;
        width: 100%;
    }
    input[type='submit'].btn-lg-block,
    input[type='reset'].btn-lg-block,
    input[type='button'].btn-lg-block {
        width: 100%;
    }
    .btn-block + .btn-lg-block,
    .btn-lg-block + .btn-block,
    .btn-lg-block + .btn-lg-block {
        margin-top: 0.5rem;
    }
}

// Printing layout helper
@media print {
    .col-print-12 {
        max-width: 100%;
        flex: 0 0 100%;
    }

    .collapse:not(.show) {
        display: inherit;
    }
}

// Mini FAQ
.accordion {
    button.btn {
        .fa {
            float: left;
            padding-right: 1rem;
        }

        span:not(.text-success) {
            display: block;
            overflow: hidden;
        }

        text-align: left;
    }
}

// Testimonial
.testimonial-card {
    blockquote {
        margin-bottom: 0;

        div > p {
            margin-bottom: 0;
        }

        .fa-star,
        .fa-star-half-o,
        .fa-star-o {
            color: #ffcc28;
        }

        footer {
            font-size: 100%;
        }
    }
}

.hero-title {
    padding: 0.5em;
    color: white;
    background-color: rgba(0, 0, 0, 0.5);
    display: inline-block;
}

.big-border {
    border-width: 3px;
}

p.no-margin-cms-list {
    ul {
        margin: 0;
    }

    margin: 0;
}

div.no-margin-md-cms {
    p {
        margin: 0;
    }
}

.optional {
    // font-weight: lighter;
}

.normal {
    font-weight: normal;
}

div.alert p:last-child {
    margin-bottom: 0;
}

// Alerts
.alert-survey {
    color: $white;
    background-color: $primary;
    border-color: $primary;

    .btn {
        color: $black;
        background-color: $BCS_yellow;
        border-color: $BCS_yellow;
    }
}

// Dropdown spinner
.dropdown-spinner {
    position: absolute;
    display: inline-block;
    font-size: 2rem;
}

body {
    min-width: 0 !important;
}
.helpdesk-info h4,
.helpdesk-info h6 {
    margin-bottom: 0px !important;
}
.helpdesk-info h4 {
    font-size: 18px !important;
}
.helpdesk-info h6 {
    font-size: 14px !important;
}
.helpdesk-info p:first-of-type {
    font-size: 12px !important;
}
.helpdesk-info p:last-of-type {
    margin-bottom: 0px !important;
}
.helpdesk-info p {
    margin-bottom: 0.5rem;
    font-size: 14px !important;
}
.helpdesk-info span {
    white-space: nowrap;
}
.helpdesk-info a {
    font-weight: bold;
    font-style: italic;
    text-decoration: underline;
    color: #000;
}
.helpdesk-brand-logo {
    object-fit: cover;
    max-height: 55px;
    max-width: 150px;
}
.helpdesk-socials i {
    font-size: 2em;
}
.helpdesk-right-column {
    padding-top: 2rem !important;
}
@include media-breakpoint-up(lg) {
    .helpdesk-container .p-lg-5 {
        padding: 30px !important;
    }
}
.active-dropdown-menu {
    color: #23833d !important;
}
.active-menu-underline {
    background-color: #23833d;
}
nav ul ul li:hover {
    border-color: #23833d !important;
}
