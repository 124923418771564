.flex-block {
    display: flex;
}

.flex-block img {
    height: 65px;
    width: auto;
}
/* Firefox bug fix */
@supports (-moz-appearance: meterbar) and (display: flex) {
    .flex-block__area {
        display: flex;
    }
}
.sticky {
    position: -webkit-sticky;
    position: -moz-sticky;
    position: -ms-sticky;
    position: -o-sticky;
    position: fixed;
}
.navWrapper {
    top: 0;
    background-color: white;
    z-index: 10;
    box-shadow: 0px 1px 1px 0px rgba(35, 131, 61, 0.3);
    margin-bottom: 1px;
    display: block;
    width: 100%;
}
.navWrapper nav {
    justify-content: space-between;
}
.menu {
    margin-left: 2rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

nav {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 5rem;
}

.logo {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.logo img {
    height: 4rem;
    align-self: center;
}

nav ul {
    display: flex !important;
    flex-direction: row !important;
    z-index: 10;
    list-style: none !important;
    position: static;
    font-weight: 700;
    font-size: 1rem;
    margin-bottom: -0.4rem !important;
}
nav ul:hover ul {
    flex-direction: column !important;
}
nav ul li {
    display: inline-block;
    border-bottom: 3px solid transparent;
    color: #000;
}

nav ul li a {
    color: black;
    text-decoration: none !important;
    line-height: 70px;
    font-size: 18px;
    padding: 0px 10px;
}

nav ul li a:hover {
    text-decoration: none !important;
    color: black;
}

nav ul li:hover > ul {
    opacity: 1;
    visibility: visible;
}

nav ul ul {
    visibility: hidden;
    display: flex;
    flex-direction: column;
    position: absolute;
    opacity: 0;
    transition: top 0.3s;
    top: 90px;
    background-color: white;
    border: 1px solid grey;
    padding-left: 0px;
    float: right;
    margin: 0 auto;
    box-shadow: 1px 1px 1px 1px rgba(35, 131, 61, 0.1);
}

nav ul ul li {
    z-index: 50;
    border-left: 3px solid transparent;
    border-bottom: none;
    font-weight: 400;
    margin-bottom: 0.2rem;
}

nav ul ul li:hover {
    border-left: 3px solid;
    border-bottom: none;
    background-color: rgba(35, 131, 61, 0.1);
}

nav ul ul li a {
    line-height: 40px;
}

nav ul li a i {
    top: 30px;
    position: sticky;
    font-size: 25px !important;
}
.navWrapper .show,
.icon {
    display: none;
}

.dropdown-toggle::after {
    content: normal;
}
.navWrapper .nav-item a {
    font-size: 17px;
}
.dropdown-item {
    padding-top: 0;
    padding-bottom: 0;
}
.dropdown-menu a {
    font-size: 14px;
}
.dropdown-menu {
    top: unset !important;
    border: none !important;
    border-radius: 0 !important;
    min-width: 100%;
    box-shadow: 0px 2px 4px rgb(0 0 0 / 20%), 0px 4px 5px rgb(0 0 0 / 14%), 0px 1px 10px rgb(0 0 0 / 12%);
}

.registerWrapper button {
    width: 100%;
    height: 100%;
    padding-left: 30px !important;
    padding-right: 30px !important;
}
.registerWrapper {
    margin-left: 40px;
    line-height: 80px;
    height: 50px;
}
.socialsWrapper {
    display: none;
}
.socialsWrapper ul {
    margin: 0 auto;
    padding-top: 0;
    margin-top: 20px;
}
.socialsWrapper ul li,
.socialsWrapper ul li:hover {
    border-bottom: none;
}
.socialsWrapper ul li a {
    display: block;
    text-align: center;
}
.socialsWrapper ul li a i {
    float: none !important;
}
.navWrapper .nav-item {
    margin-left: 20px;
    margin-top: 0.4rem;
}
.navWrapper .nav-item i {
    padding-left: 1rem;
    position: inherit;
}
.active-menu-underline {
    width: 100%;
    height: 5px;
    margin-top: -12px;
}
.dropdown-menu li a:hover {
    font-weight: bold !important;
}
.logo img:last-child {
    padding-left: 5px;
}
.add-nav-margin {
    height: 80px;
    width: 100%;
    display: block;
    position: relative;
}
nav .second-logo {
    display: inline-block;
    a {
        display: block;
        max-width: 180px;
        max-height: 65px;
    }
}
@media (max-width: 991px) {
    .add-nav-margin {
        height: 70px;
    }
}
@media all and (max-width: 1200px) {
    .menu-open {
        overflow: hidden;
    }

    .menu {
        display: none;
    }

    .fullNav {
        position: fixed;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
    }

    .container {
        flex-flow: row wrap;
    }

    .flex > * {
        flex: 1 100%;
    }

    .icon {
        display: block;
        float: right;
        font-size: 2rem;
        cursor: pointer;
        flex: 0 auto;
        margin-right: 5px;
        position: absolute;
        right: 20px;
        color: #000 !important;
        line-height: 70px;
    }

    .fullNav > nav > .menu {
        display: flex;
        flex-direction: column;
        order: 3;
        margin-left: 0;
        width: 100%;
    }

    nav ul {
        display: flex;
        flex-flow: row wrap;
        padding-left: 0;
    }

    nav .logo {
        flex: 1 auto;
    }

    nav ul li {
        display: block;
        flex-flow: row wrap;
        flex: 1 100%;
        float: left;
        border-bottom: 0.1rem inset rgba(35, 131, 61, 0.3);
    }

    nav ul li:hover {
        flex: 1 100%;
        border-bottom: 0.1rem inset rgba(35, 131, 61, 0.3);
    }

    nav ul ul {
        flex: 1 100%;
        flex-flow: row wrap;
        position: relative;
        display: block;
        opacity: 1;
        visibility: visible;
        box-shadow: none;
        border: none;
        margin-left: 1.5rem;
        padding-top: 0rem !important;
        margin-top: -1.5rem;
    }

    .navWrapper .show {
        display: block;
        font-size: 18px;
        line-height: 70px;
        cursor: pointer;
    }

    nav ul li a:hover {
        box-shadow: none;
    }

    .navWrapper .show + a,
    .navWrapper ul {
        display: none;
    }

    nav ul li label {
        color: black;
    }

    nav ul li input:checked ~ label {
        color: #23833d !important;
    }

    .navbar-nav .dropdown-menu {
        border-left: none !important;
        border-right: none !important;
        border-top: none !important;
        border-bottom: 0.1rem inset rgba(35, 131, 61, 0.3) !important;
    }
    nav ul li a {
        line-height: 58px;
    }
    nav ul li a i {
        margin-top: 20px;
        float: right;
    }
    .registerWrapper {
        margin-top: 15px;
        margin-left: 0px;
    }
    .socialsWrapper {
        display: flex;
    }
    .navWrapper .nav-link {
        padding: 0;
        line-height: 58px;
    }
    .dropdown-item {
        line-height: 40px;
    }
    .navWrapper .nav-item {
        margin-left: 0 !important;
    }
    .active-menu-underline {
        display: none;
    }
    .dropdown-menu {
        width: 100%;
        box-shadow: none;
    }
    .navWrapper,
    .dropdown-menu,
    .dropdown-menu li,
    .dropdown-menu li a {
        z-index: 100 !important;
    }
    .dropdown-menu {
        position: relative !important;
    }
    .nav-item {
        z-index: 0 !important;
    }
    .nav-item,
    .nav-link,
    .dropdown {
        position: relative !important;
    }
    .dropdown-menu {
        margin-top: -75px;
        margin-bottom: 40px;
    }
    .navWrapper {
        margin-bottom: 0;
    }
    .logo {
        margin-bottom: 7px;
        margin-top: 7px;
    }
    .navWrapper .nav-item i {
        position: initial;
    }
}
.navWrapper .nav-link {
    font-size: 1.2rem;
}
.navbar-nav .dropdown-menu {
    position: absolute;
    float: left;
}
